import React from 'react';

export const PromptBox = ({ isVisible, promptText, setPromptText, handleAutoSend, position, promptBoxRef }) => {
  // NOTE: Adjust these values to change the position of the prompt box
  const offsetX = -220; // Positive values move the box right, negative values move it left
  const offsetY = 10; // Positive values move the box down, negative values move it up

  return (
    <div 
      ref={promptBoxRef}
      className={`fixed bg-white p-4 rounded-lg shadow-lg w-80 z-50 transition-all duration-300 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'
      }`}
      style={{
        top: `${Math.min(position.top + position.height + offsetY, window.innerHeight - 300)}px`,
        left: `${Math.min(Math.max(position.left + offsetX, 20), window.innerWidth - 340)}px`,
        maxWidth: 'calc(100vw - 40px)',
        maxHeight: 'calc(100vh - 40px)',
      }}
    >
      <textarea
        className="w-full h-32 p-2 mb-4 bg-gray-100 rounded-md resize-none"
        value={promptText}
        onChange={(e) => setPromptText(e.target.value)}
        placeholder="Enter your prompt here..."
      />
      <div className="flex justify-center">
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors duration-200"
          onClick={handleAutoSend}
        >
          Auto Send
        </button>
      </div>
    </div>
  );
};