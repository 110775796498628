import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderMainPage from '../../header/HeaderMainPage';
import { FaReply, FaSearch } from "react-icons/fa";
// import mailData from '../maildata_sample.json';
import Swal from "sweetalert2";

// Define column widths
const columnWidths = {
    star: '50px',
    email: '150px',
    date: '100px',
};

const MailboxContent = () => {

    const roboman_api = process.env.REACT_APP_ROBOMAN_API;
    const campaignId = localStorage.getItem("campaign_id");
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [replyBox, setReplyBox] = useState(false);
    const [replySubject, setReplySubject] = useState("");
    const [replyContent, setReplyContent] = useState("");
    const [sentMails, setSentMails] = useState([]);
    const [selected_internal_id, setSelectedInternalID] = useState(null);
    const [replyHistory, setReplyHistory] = useState([]);
    const authToken = localStorage.getItem("access_token");

    useEffect(() => {
        const fetchSentMails = async () => {
            try {
                const response = await fetch(`${roboman_api}/campaign/${campaignId}/emails/sent-mails`, {
                    method: "GET",
                    headers: {
                        "accept": "application/json",
                        "Authorization": authToken,
                    },
                });

                if (!response.ok) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Failed to fetch sent emails.",
                        confirmButtonText: "OK"
                    });
                    return; // Exit the function early
                }

                const data = await response.json();
                // console.log("Fetched sent mails:", data);
                setSentMails(data);
            } catch (error) {
                Swal.fire({
                    title: "Error!",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "Okay"
                });
            }
        };
        fetchSentMails();
    }, []);

    const handleMailReplyHistory = async (sent_email_id) => {
        try {
            const response = await fetch(`${roboman_api}/campaign/emails/${sent_email_id}/reply-mails`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": authToken, // Replace with your actual token
                    },
                }
            );
    
            if (!response.ok) {
                console.error(`Error: ${response.status}`);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `Failed to load email reply history. Please try again`,
                });
                return;
            }
    
            const data = await response.json();
            setReplyHistory(data); // Save the API response to state
        } catch (error) {
            console.error("Failed to fetch mail reply history:", error);
             // Show error alert
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to load email reply history. Please try again later.",
            });
        }
    };

    // Function to handle email click
    const handleEmailClick = (internal_id) => {
        setSelectedInternalID(internal_id);
        setReplyBox(false); // Hide reply box when switching emails
        handleMailReplyHistory(internal_id); // Fetch reply history for the selected email
    };

    // Function to handle reply
    const handleReply = () => {
        if (!replySubject.trim() || !replyContent.trim()) return; // Prevent empty replies

        const newMail = {
            sender_name: "You",
            receiver_time: new Date().toISOString(),
            mail_subject: replySubject,
            mail_content: replyContent,
        };

        // Update the selected company's mail history
        setSelectedCompany((prev) => ({
            ...prev,
            mail_box: [newMail, ...prev.mail_box], // Add new mail to the top
        }));

        // Reset fields and hide reply box
        setReplySubject("");
        setReplyContent("");
        setReplyBox(false);
    };

    // Function to discard reply
    const handleDiscard = () => {
        setReplyBox(false);
        setReplySubject("");
        setReplyContent("");
    };

    return (
        <div className="flex flex-row gap-4 flex-1 overflow-hidden">
            {/* Left Panel: Email List */}
            <div className="w-1/3 bg-gray-100 p-4 rounded-md shadow-sm h-full overflow-y-auto">
                {/* Show nothing if sentMails is empty */}
                {sentMails.length > 0 && (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 sticky top-0">
                            <tr>
                                <th style={{ width: "50px" }}></th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Email
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sentMails.map((mail, index) => (
                                <tr
                                    key={index}
                                    className={`hover:bg-gray-200 border-b border-gray-300 cursor-pointer ${
                                        selected_internal_id === mail.internal_id ? "bg-gray-300" : ""
                                    }`}
                                    onClick={() => handleEmailClick(mail.internal_id)}
                                >
                                    <td className="px-4 py-4"></td>
                                    <td className="px-6 py-4 text-md font-medium text-gray-900">
                                        {mail.to_addresses.length > 0 ? mail.to_addresses[0] : "Unknown"}
                                    </td>
                                    <td className="px-6 py-4 text-md text-gray-500">
                                        {mail.updated_at ? new Date(mail.updated_at).toLocaleString() : "N/A"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            {/* Right Panel: Mail History */}
            <div className="w-2/3 flex flex-col gap-4 h-full">
                <div className="flex-1 bg-gray-100 p-4 rounded-md shadow-sm overflow-y-auto flex flex-col">
                    {replyHistory && replyHistory.length > 0 ? (
                        <>
                            {/* Mail History */}
                            <div className="flex-1 space-y-4">
                                {replyHistory
                                    .slice()
                                    .sort((a, b) => new Date(a.receiver_time) - new Date(b.receiver_time)) // Sort oldest first
                                    .map((mail, idx) => (
                                        <div key={idx} className="bg-white p-4 rounded-md shadow">
                                            <div className="flex justify-between items-center mb-2">
                                                <h2 className="text-lg font-semibold">
                                                    {mail.from_info?.display_name || mail.from_info?.identifier || "Unknown Sender"}
                                                </h2>
                                                <span className="text-xs text-gray-500">
                                                    {mail.created_at ? new Date(mail.created_at).toLocaleString() : "N/A"}
                                                </span>
                                            </div>
                                            <h3 className="text-md font-medium text-gray-700">{mail.subject}</h3>
                                            <div className="text-gray-700 mt-2 space-y-2">
                                                {mail.body_plain.split("\n\n").map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            {/* Reply Box at the Bottom */}
                            {replyBox && (
                                <div className="bg-white p-4 rounded-md shadow border mt-4">
                                    <input
                                        type="text"
                                        placeholder="Subject"
                                        className="w-full p-2 border rounded-md mb-2"
                                        value={replySubject}
                                        onChange={(e) => setReplySubject(e.target.value)}
                                    />
                                    <textarea
                                        rows="3"
                                        placeholder="Content"
                                        className="w-full p-2 border rounded-md"
                                        value={replyContent}
                                        onChange={(e) => setReplyContent(e.target.value)}
                                    />
                                    <div className="flex justify-end gap-2 mt-2">
                                        <button
                                            className="bg-[#223F9E] text-white px-4 py-2 rounded-full hover:border hover:border-blue-700"
                                            onClick={handleReply}
                                        >
                                            Reply
                                        </button>
                                        <button
                                            className="bg-gray-300 text-black px-4 py-2 rounded-full hover:border hover:border-gray-500"
                                            onClick={handleDiscard}
                                        >
                                            Discard
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex h-full items-center justify-center">
                            <h2 className="text-xl font-semibold text-gray-500">Select an email to view the conversation.</h2>
                        </div>
                    )}
                </div>

                {/* Reply Button */}
                {replyHistory.length > 0 && !replyBox && (
                    <div className="w-full flex justify-end">
                        <button
                            className="bg-green-500 text-white px-4 py-2 rounded-full flex items-center gap-2"
                            onClick={() => setReplyBox(true)}
                        >
                            Reply <FaReply />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const Unibox = () => {

    const navigate = useNavigate();
    
    const handleBack = () => {
        navigate('/email-status');
    };
    
    const Footer = () => {
        return (
            <div className='bg-white flex justify-end'>
                <div className="mb-3 mt-3">
                    <button 
                    className="bg-[#223F9E] text-white font-semibold py-2 px-10 mr-[50px] rounded-full"
                    onClick={handleBack}
                    >
                    Back
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="min-h-screen flex flex-col bg-white overflow-hidden">
            <HeaderMainPage />
            
            {/* Page Content */}
            <div className="flex justify-center items-center flex-grow p-4">
                {/* Main Box */}
                <div className="bg-white w-full h-[80vh] shadow-md roun2ed-md p-4 flex flex-col">
                {/* Object 1 */}
                <div className="rounded p-4 mb-4 flex justify-between items-center">
                    {/* Left Section */}
                    <div>
                        <h1 className="text-xl font-bold">{localStorage.getItem("campaign_name")}</h1>
                        <h2 className="text-lg text-gray-600">{localStorage.getItem("core_service")}</h2>
                    </div>
                    
                    {/* Search Box */}
                    <div className="relative flex-grow w-full max-w-xs">
                        <input
                            type="text"
                            placeholder="Search"
                            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FaSearch className="h-5 w-5 text-gray-400" />
                        </div>
                    </div>
                </div>
        
                {/* Object 2 - Using MailboxContent */}
                <MailboxContent />

                </div>
            </div>
        
            <Footer />
        </div>
    );
}

export default Unibox;