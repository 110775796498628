import React, { useEffect, useState, useRef } from 'react';
import {OnboardingHeaderPart} from '../../header/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import discInfo from '../../assets/disc_info.json';
import oceanInfo from '../../assets/ocean_info.json';
import Swal from 'sweetalert2';
import { FaMapMarkerAlt } from "react-icons/fa";
import * as d3 from 'd3';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Registering the components necessary for the chart
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);


const InformationPanel = ({ title, children }) => {
    return (
        <div className="w-full border-b border-gray-300">
            {/* Title Panel */}
            <div className="p-4 bg-gray-100">
                <h1 className="text-gray-800 font-medium text-lg">{title}</h1>
            </div>
            {/* Content Panel */}
            <div className="bg-gray-50">
                <div className="p-4">{children}</div>
            </div>
        </div>
    );
};

const ViewInsight = () => {
    const navigate = useNavigate();
    const [selectedProfile, setSelectedProfile] = useState("DISC");

    const [userData, setUserData] = useState(null);
    const apikey = process.env.REACT_APP_HUMANTICAI_KEY;
    const company_id = localStorage.getItem("company_id")
    const [isDataLoad, setDataLoad] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const roboman_api = process.env.REACT_APP_ROBOMAN_API;


    const handlingFetchLinkedIn = async (company_id) => {
        try {
          const response = await fetch(`${roboman_api}/campaign/companies/${company_id}/representative/`,{
            method: 'GET',
            headers: {
                Accept: "application/json",
                Authorization: localStorage.getItem("access_token"), // Replace 'code' with your actual token
              },
          });
          if (!response.ok) {
            console.error('API is not running');
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching LinkedIn data:', error);
          return null;
        }
    };

    useEffect(() => {
        const loadUserData = async () => {
            const storedData = await handlingFetchLinkedIn(company_id);
            // const storedData = []
            // console.log("data sample:", storedData)

            if (storedData.analysis_status === "NOT_FOUND") {
                console.error('Profile not found');
                Swal.fire({
                    icon: 'error', // Changed to 'error' for error theme
                    title: 'Profile Not Found', // Adjusted the title for the error context
                    text: 'This profile is not found in our database or cannot be processed.', // Adjusted the error message
                    confirmButtonColor: "#223F9E",
                    confirmButtonText: 'Close',
                    preConfirm: () => {
                        // Call the handleBack function here
                        handleBack();
                    }
                });
            } else {
                console.log('Profile found');
                setUserData(storedData);
            }
        };

        if (isDataLoad === false){
            loadUserData();
            setDataLoad(true);
            setLoading(false);
        }
    }, [isDataLoad]);

    const DISC_info = () => {
        if (!userData) {
            return (
                <div className="w-full h-[300px] items-center shadow-md flex justify-center items-center">
                    <div className="text-center text-gray-500">Data is loading</div>
                </div>
            );
        }
    
        // Extract the necessary information from userData
        const { label, description } = userData.personality_analysis.summary.disc;
    
        if (!label || !description || label.length === 0 || description.length === 0) {
            return (
                <div className="w-full h-[300px] items-center shadow-md flex justify-center items-center">
                    <div className="text-center text-gray-500">No DISC data available</div>
                </div>
            );
        }
    
        // Match the labels with the descriptions in the JSON
        const matchedDiscData = label.map((lbl) => {
            const matchingTrait = discInfo.disc.find(
                (trait) => trait.abbreviation.toLowerCase() === lbl.toLowerCase()
            );
            return {
                title: description[label.indexOf(lbl)], // Ensure descriptions align with labels
                description: matchingTrait ? matchingTrait.description : "Description not found",
            };
        });
    
        // Render a box for each label and description
        return (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
                {matchedDiscData.map((data, index) => (
                    <div key={index} className="p-4 border shadow-md rounded-md bg-white">
                        <div className="font-bold text-lg">{data.title}</div>
                        <p className="text-gray-700 mt-2">{data.description}</p>
                    </div>
                ))}
            </div>
        );
    };
    
    const DISC_chart = () => {
        const chartRef = useRef(null);
    
        useEffect(() => {
            if (!userData || !userData.personality_analysis?.disc_assessment) return;
    
            // Get the DISC data
            const discData = userData.personality_analysis.disc_assessment;
    
            if (!discData) {
                return; // Exit if DISC data is not available
            }
    
            const discChartData = [
                { label: 'I', score: discData.influence.score, color: '#FFCE56' },
                { label: 'S', score: discData.steadiness.score, color: '#4BC0C0' },
                { label: 'C', score: discData.calculativeness.score, color: '#36A2EB' },
                { label: 'D', score: discData.dominance.score, color: '#FF6384' },  
            ];
    
            // Chart Dimensions
            const maxScore = 10;
            const radius = 75; // Smaller radius for the chart
            const innerRadius = 15; // Smaller inner radius
            const width = 250; // Set chart width to be under 300px
            const height = 280; // Set chart height to be under 300px
    
            // Select SVG and clear previous chart
            const svg = d3.select(chartRef.current);
            svg.selectAll('*').remove(); // Clear existing chart elements
            
            const space_from_top = 40
            // Create chart container
            const chart = svg
                .attr('viewBox', `0 0 ${width} ${height}`)
                .append('g')
                .attr('transform', `translate(${width / 2}, ${height / 2 - space_from_top})`);
    
            // Arc generator
            const arc = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius((d) => innerRadius + (radius - innerRadius) * (d.score / maxScore))
                .startAngle((d, i) => (i * 2 * Math.PI) / 4)
                .endAngle((d, i) => ((i + 1) * 2 * Math.PI) / 4)
                .padAngle(0.05)
                .padRadius(innerRadius);

            // Add radial bars
            chart.selectAll('path')
            .data(discChartData)
            .join('path')
            .attr('d', arc)
            .attr('fill', (d) => d.color) // Set the initial color
            .on('mouseover', function (event, d) {
                // Highlight the hovered bar by keeping its original color
                d3.select(this).attr('fill', d.color);

                // Darken the colors of all other bars
                chart.selectAll('path')
                    .filter((bar) => bar !== d)
                    .attr('fill', (bar) => d3.color(bar.color).darker(1)); // Darken by one level

                // Show tooltip
                tooltip.style('visibility', 'visible')
                    .text(`${d.label}: ${d.score}`);
            })
            .on('mousemove', function (event) {
                tooltip.style('top', event.pageY - 10 + 'px')
                    .style('left', event.pageX + 10 + 'px');
            })
            .on('mouseout', function () {
                // Reset all bars to their original color
                chart.selectAll('path')
                    .attr('fill', (bar) => bar.color);

                // Hide tooltip
                tooltip.style('visibility', 'hidden');
            });
    
            // Add central gray circle with the category having the highest score
            const highestCategory = discChartData.reduce((prev, curr) => (prev.score > curr.score ? prev : curr));
    
            chart.append('circle')
                .attr('r', innerRadius)
                .attr('fill', '#ccc'); // Gray background for the center circle
    
            chart.append('text')
                .attr('text-anchor', 'middle')
                .attr('font-size', '16px')
                .attr('fill', '#fff')
                .attr('dy', '.3em')
                .text(highestCategory.label); // Display the label of the highest scoring category in the center
    
            // Add dashed circles for reference values
            chart.append('circle')
            .attr('r', innerRadius + (radius - innerRadius) * (5 / maxScore)) // Circle for value 5
            .attr('fill', 'none')
            .attr('stroke', '#999')
            .attr('stroke-dasharray', '4,4') // Dashed line
            .attr('stroke-width', 1);

            chart.append('circle')
            .attr('r', innerRadius + (radius - innerRadius) * (10 / maxScore)) // Circle for value 10
            .attr('fill', 'none')
            .attr('stroke', '#666')
            .attr('stroke-dasharray', '4,4') // Dashed line
            .attr('stroke-width', 1);

            // Add category labels outside the radial bars
            const labelDistance = radius + 15

            chart.selectAll('g.category')
            .data(discChartData)
            .join('g')
            .attr('class', 'category')
            .each(function (d, i) {
                const group = d3.select(this);
                const angle = (i * 2 * Math.PI) / 4 - Math.PI / 4; // Adjust starting position
                const labelX = labelDistance * Math.cos(angle);
                const labelY = labelDistance * Math.sin(angle);

                // Add a circle behind the label
                group.append('circle')
                    .attr('cx', labelX)
                    .attr('cy', labelY)
                    .attr('r', 10) // Adjust radius for circle size
                    .attr('fill', d.color);

                // Add the label text
                group.append('text')
                    .attr('class', 'category-label')
                    .attr('x', labelX)
                    .attr('y', labelY)
                    .attr('dy', '0.35em') // Center the text vertically
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '12px')
                    .attr('fill', '#FFFFFF') // White text color for contrast
                    .text(d.label); // Use the original label (D, I, S, C)
            });
    
            // Create tooltip
            const tooltip = d3.select('body')
                .append('div')
                .attr('class', 'tooltip')
                .style('position', 'absolute')
                .style('background', '#fff')
                .style('padding', '5px 10px')
                .style('border', '1px solid #ccc')
                .style('border-radius', '4px')
                .style('visibility', 'hidden');

            // Create legend container below the chart
            const legendContainer = svg.append('g')
            .attr('transform', `translate(0, ${height - space_from_top - 40})`); // Translate legend to below the chart

            // Number of columns for the grid layout
            const columns = 2;
            const spacing = 30; // Space between legend items

            // Add legend items
            legendContainer
            .selectAll('g.legend')
            .data(discChartData)
            .join('g')
            .attr('class', 'legend')
            .attr('transform', (d, i) => {
                // Calculate the row and column for each item
                const row = Math.floor(i / columns);  // Determine row index
                const col = i % columns;  // Determine column index
                const xPos = col * (spacing * 5);  // Horizontal position
                const yPos = row * spacing;  // Vertical position

                return `translate(${xPos}, ${yPos})`;  // Apply the calculated position
            })
            .each(function (d) {
                const group = d3.select(this);

                // Add the color square
                group.append('rect')
                    .attr('width', 20)
                    .attr('height', 20)
                    .attr('fill', d.color);

                // Add the label text with "D is for Dominance" format
                group.append('text')
                    .attr('x', 25)
                    .attr('y', 15)
                    .attr('font-size', '12px')
                    .attr('fill', '#333')
                    .text(`${getFullLabel(d.label)}`); // Display detailed label
            });

            // Helper function to get full label description
            function getFullLabel(label) {
            const fullLabels = {
                D: 'Dominance',
                I: 'Influence',
                S: 'Steadiness',
                C: 'Calculativeness'
            };
            return fullLabels[label] || label; // Return the full description based on the label
            }
    
        }, [userData]); // Run effect when userData changes
    
        // Loading and fallback states
        if (!userData || !userData.personality_analysis?.disc_assessment) {
            return (
                <div className="w-full h-[300px] shadow-md flex justify-center items-center">
                    <div className="text-center text-gray-500">Data is loading</div>
                </div>
            );
        }
    
        if (!userData.personality_analysis.disc_assessment) {
            return (
                <div className="w-full h-[300px] shadow-md flex justify-center items-center">
                    <div className="text-center text-gray-500">DISC data is not available</div>
                </div>
            );
        }
    
        return <svg ref={chartRef} width="70%" height="70%" style={{ backgroundColor: 'white' }}></svg>;
    };
    
    const OCEAN_info = () => {
        if (!userData) {
            return (
                <div className="w-full h-full items-center shadow-md flex justify-center items-center">
                    <div className="text-center text-gray-500">Data is loading</div>
                </div>
            );
        }
    
        const { label, description } = userData.personality_analysis.summary.ocean;
    
        if (!label || !description || label.length === 0 || description.length === 0) {
            return (
                <div className="w-full h-full items-center shadow-md flex justify-center items-center">
                    <div className="text-center text-gray-500">No OCEAN data available</div>
                </div>
            );
        }
    
        const matchedOceanData = label.map((lbl) => {
            const matchingTrait = oceanInfo.ocean.find(
                (trait) => trait.abbreviation.toLowerCase() === lbl.toLowerCase()
            );
            return {
                title: description[label.indexOf(lbl)], // Ensure descriptions align with labels
                description: matchingTrait ? matchingTrait.description : "Description not found",
            };
        });
    
        return (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-4">
                {matchedOceanData.map((data, index) => (
                    <div key={index} className="p-4 border shadow-md rounded-md bg-white">
                        <div className="font-bold text-lg">{data.title}</div>
                        <p className="text-gray-700 mt-2">{data.description}</p>
                    </div>
                ))}
            </div>
        );
    };
    
    const OCEAN_chart = () => {
        const chartRef = useRef(null);
    
        useEffect(() => {
            if (!userData || !userData.personality_analysis?.ocean_assessment) return;
    
            // Get the OCEAN data
            const oceanData = userData.personality_analysis.ocean_assessment;
    
            if (!oceanData) {
                return; // Exit if OCEAN data is not available
            }
    
            const oceanChartData = [
                { label: 'O', score: oceanData.openness.score, color: '#FFCE56' },
                { label: 'C', score: oceanData.conscientiousness.score, color: '#4BC0C0' },
                { label: 'E', score: oceanData.extraversion.score, color: '#36A2EB' },
                { label: 'A', score: oceanData.agreeableness.score, color: '#FF6384' },
                { label: 'N', score: oceanData.emotional_stability.score, color: '#9966FF' },
            ];
    
            // Chart Dimensions
            const maxScore = 10;
            const radius = 100;
            const innerRadius = 20;
            const width = 350;
            const height = 400;
    
            // Select SVG and clear previous chart
            const svg = d3.select(chartRef.current);
            svg.selectAll('*').remove();
    
            const space_from_top = 50;
    
            // Create chart container
            const chart = svg
                .attr('viewBox', `0 0 ${width} ${height}`)
                .append('g')
                .attr('transform', `translate(${width / 2}, ${height / 2 - space_from_top})`);
    
            // Arc generator (counterclockwise)
            const arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius((d) => innerRadius + (radius - innerRadius) * (d.score / maxScore))
            .startAngle((d, i) => ((i + 1) * 2 * Math.PI) / 5)  // Reverse the order of start and end angles
            .endAngle((d, i) => (i * 2 * Math.PI) / 5)           // Reverse the order of start and end angles
            .padAngle(0.05)
            .padRadius(innerRadius);

            // Add radial bars
            chart.selectAll('path')
            .data(oceanChartData)
            .join('path')
            .attr('d', arc)
            .attr('fill', (d) => d.color)
            .on('mouseover', function (event, d) {
                d3.select(this).attr('fill', d.color);

                chart.selectAll('path')
                    .filter((bar) => bar !== d)
                    .attr('fill', (bar) => d3.color(bar.color).darker(1));

                tooltip.style('visibility', 'visible')
                    .text(`${d.label}: ${d.score}`);
            })
            .on('mousemove', function (event) {
                tooltip.style('top', event.pageY - 10 + 'px')
                    .style('left', event.pageX + 10 + 'px');
            })
            .on('mouseout', function () {
                chart.selectAll('path')
                    .attr('fill', (bar) => bar.color);

                tooltip.style('visibility', 'hidden');
            });
    
            // Find the top 3 categories by score
            const topCategories = oceanChartData
                .sort((a, b) => b.score - a.score) // Sort by descending score
                .slice(0, 3); // Get the top 3 categories

            // Concatenate the labels of the top 3 categories
            const topLabels = topCategories.map((category) => category.label).join('');

            // Add central gray circle
            chart.append('circle')
                .attr('r', innerRadius)
                .attr('fill', '#ccc');

            // Add text for the top 3 categories
            chart.append('text')
                .attr('text-anchor', 'middle')
                .attr('font-size', '16px')
                .attr('fill', '#fff')
                .attr('dy', '.3em')
                .text(topLabels);
    
            // Add dashed circles for reference values
            chart.append('circle')
                .attr('r', innerRadius + (radius - innerRadius) * (5 / maxScore))
                .attr('fill', 'none')
                .attr('stroke', '#999')
                .attr('stroke-dasharray', '4,4')
                .attr('stroke-width', 1);
    
            chart.append('circle')
                .attr('r', innerRadius + (radius - innerRadius) * (10 / maxScore))
                .attr('fill', 'none')
                .attr('stroke', '#666')
                .attr('stroke-dasharray', '4,4')
                .attr('stroke-width', 1);
    
            // Add category labels outside the radial bars
            const labelDistance = radius + 15;
    
            // Manually reorder the data for counterclockwise direction
            const reorderedData = ['O', 'C', 'E', 'A', 'N']; // Specify the correct order explicitly
            const reorderedChartData = reorderedData.map((label) => oceanChartData.find(d => d.label === label));

            // Arc positioning (clockwise or counterclockwise)
            chart.selectAll('g.category')
                .data(reorderedChartData)  // Use the reordered data
                .join('g')
                .attr('class', 'category')
                .each(function (d, i) {
                    const group = d3.select(this);

                    // Correct angle for counterclockwise order starting from 12 o'clock
                    const angle = (i * 2 * Math.PI) / 5 - Math.PI / 3.5; // Adjust for counterclockwise order
                    const labelX = labelDistance * Math.cos(angle);
                    const labelY = labelDistance * Math.sin(angle);

                    group.append('circle')
                        .attr('cx', labelX)
                        .attr('cy', labelY)
                        .attr('r', 10)
                        .attr('fill', d.color);

                    group.append('text')
                        .attr('class', 'category-label')
                        .attr('x', labelX)
                        .attr('y', labelY)
                        .attr('dy', '0.35em')
                        .attr('text-anchor', 'middle')
                        .attr('font-size', '12px')
                        .attr('fill', '#FFFFFF')
                        .text(d.label);
                });

    
            // Create tooltip
            const tooltip = d3.select('body')
                .append('div')
                .attr('class', 'tooltip')
                .style('position', 'absolute')
                .style('background', '#fff')
                .style('padding', '5px 10px')
                .style('border', '1px solid #ccc')
                .style('border-radius', '4px')
                .style('visibility', 'hidden');
    
            // Create legend container below the chart
            const legendContainer = svg.append('g')
                .attr('transform', `translate(-20, ${height - space_from_top - 40})`);
    
            const columns = 3;
            const spacing = 30;
    
            legendContainer
                .selectAll('g.legend')
                .data(oceanChartData)
                .join('g')
                .attr('class', 'legend')
                .attr('transform', (d, i) => {
                    const row = Math.floor(i / columns);
                    const col = i % columns;
                    const xPos = col * (spacing * 5);
                    const yPos = row * spacing;
    
                    return `translate(${xPos}, ${yPos})`;
                })
                .each(function (d) {
                    const group = d3.select(this);
    
                    group.append('rect')
                        .attr('width', 20)
                        .attr('height', 20)
                        .attr('fill', d.color);
    
                    group.append('text')
                        .attr('x', 25)
                        .attr('y', 15)
                        .attr('font-size', '12px')
                        .attr('fill', '#333')
                        .text(`${getFullLabel(d.label)}`);
                });
    
            function getFullLabel(label) {
                const fullLabels = {
                    O: 'Openness',
                    C: 'Conscientiousness',
                    E: 'Extraversion',
                    A: 'Agreeableness',
                    N: 'Emotional Stability'
                };
                return fullLabels[label] || label;
            }
    
        }, [userData]);
    
        if (!userData || !userData.personality_analysis?.ocean_assessment) {
            return (
                <div className="w-full h-[300px] shadow-md flex justify-center items-center">
                    <div className="text-center text-gray-500">Data is loading</div>
                </div>
            );
        }
    
        return <svg ref={chartRef} width="70%" height="70%" style={{ backgroundColor: 'white' }}></svg>;
    };
    
    const ProfileContent = ({ profile }) => {
        const renderContent = (ChartComponent, InfoComponent) => (
            <div className="flex flex-col items-center justify-center border rounded-lg bg-gray-100 p-1">
                {/* Chart */}
                <div className="w-full flex justify-center items-center">
                    <ChartComponent />
                </div>
                {/* Info */}
                <div className="w-full">
                    <InfoComponent />
                </div>
            </div>
        );
    
        if (profile === "DISC") {
            return (
                <div className="grid grid-cols-1 gap-4 w-full h-full">
                    {renderContent(DISC_chart, DISC_info)}
                </div>
            );
        } else if (profile === "OCEAN") {
            return (
                <div className="grid grid-cols-1 gap-4 w-full h-full">
                    {renderContent(OCEAN_chart, OCEAN_info)}
                </div>
            );
        }
    
        return null; // Handle other cases if needed
    };
    
    // Handle profile click
    const handleProfileClick = (profile) => {
        setSelectedProfile(profile);
    };

    const handleBack = () => {
        navigate('/email-status');
        localStorage.removeItem("LinkedInlink")
    };

    const InsightsCallMeeting = () => {
        // Check if userData is null
        if (!userData) {
            return (
                <div
                    className="flex items-center justify-center bg-white"
                    style={{
                        width: '90vw',
                        height: '90vh',
                        margin: 'auto',
                    }}
                >
                    {/* Placeholder for no data */}
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }
    
        // Extract communication advice data
        const adviceData = userData.communication_advice;
        const { what_to_say, what_to_avoid } = adviceData;
    
        if (adviceData) {
            return (
                <div className="flex-grow flex flex-col items-center space-y-8 py-2 px-2 md:px-2">
    
                    {/* Grid layout */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                        {/* DO's section */}
                        <div className="p-6 text-white rounded-lg shadow-md bg-[#789e87]">
                            <h1 className="font-semibold text-2xl mb-4">DO's</h1>
                            <ul className="list-disc pl-4 space-y-2">
                                {what_to_say.map((text, index) => (
                                    <li key={index}>{text}</li>
                                ))}
                            </ul>
                        </div>
                        {/* DON'Ts section */}
                        <div className="p-6 text-white rounded-lg shadow-md bg-[#b97778]">
                            <h1 className="font-semibold text-2xl mb-4">DON'Ts</h1>
                            <ul className="list-disc pl-4 space-y-2">
                                {what_to_avoid.map((text, index) => (
                                    <li key={index}>{text}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="flex items-center justify-center bg-white"
                    style={{
                        width: '90vw',
                        height: '90vh',
                        margin: 'auto',
                    }}
                >
                    <div className="text-gray-500 text-xl">This Profile cannot be analyzed</div>
                </div>
            );
        }
    };
    
    const InsightsNegotiatingClosing = () => {
        if (!userData) {
            return (
                <div
                    className="flex items-center justify-center bg-white"
                    style={{
                        width: '90vw',
                        height: '90vh',
                        margin: 'auto',
                    }}
                >
                    {/* Placeholder for no data */}
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }
    
        // Destructure the required data
        const { first_name } = userData.rep_name;
        const decisionDrivers = userData.communication_advice.key_traits["Decision Drivers"];
        const abilityToSayNo = userData.communication_advice.key_traits["Ability To Say No"];
    
        if (decisionDrivers && abilityToSayNo) {
            return (
                <div className="flex flex-col p-6 rounded-md shadow-md bg-[#e1f1e6]">
                    {/* Title 1 */}
                    <div className="mb-6">
                        <h1 className="text-xl font-bold">
                            The secret to closing fast with {first_name} is
                        </h1>
                        <p className="mt-2 text-gray-700">* {decisionDrivers}</p>
                    </div>
                    {/* Title 2 */}
                    <div className="mb-6">
                        <h1 className="text-xl font-bold">
                            Will you ever get a clear answer from {first_name}
                        </h1>
                        <p className="mt-2 text-gray-700">* {abilityToSayNo}</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="flex items-center justify-center bg-white"
                    style={{
                        width: '90vw',
                        height: '90vh',
                        margin: 'auto',
                    }}
                >
                    <div className="text-gray-500 text-xl">This Profile cannot be analyzed</div>
                </div>
            );
        }
    };
    
    
    const InsightsColdCalling = () => {
        if (!userData) {
            return (
                <div className="flex items-center justify-center bg-white w-[90vw] h-[90vh] m-auto">
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }
    
        const insights = userData.cold_calling_advice.insights;
        const examples = userData.cold_calling_advice.examples;
    
        if (insights && examples) {
            return (
                <div className="flex flex-col md:grid md:grid-cols-2 gap-4 p-3 min-h-screen">
                    {/* Left Panel */}
                    <div className="bg-[#e2ddea] rounded-lg border border-gray-300 p-6">
                        <h1 className="text-2xl font-bold mb-4">Insights</h1>
                        {Object.entries(insights).map(([title, description]) => (
                            <div key={title} className="mb-4">
                                <h2 className="text-lg font-semibold">{title}</h2>
                                <p className="text-gray-700">{description}</p>
                            </div>
                        ))}
                    </div>
    
                    {/* Right Panel */}
                    <div className="bg-[#e2ddea] rounded-lg border border-gray-300 p-6">
                        <h1 className="text-2xl font-bold mb-4">Examples</h1>
                        {Object.entries(examples).map(([title, example]) => (
                            <div key={title} className="mb-4">
                                <h2 className="text-lg font-semibold">{title}</h2>
                                <p className="text-gray-700">{example}</p>
                            </div>
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex items-center justify-center bg-white w-[90vw] h-[90vh] m-auto">
                    <div className="text-gray-500 text-xl">This Profile cannot be analyzed</div>
                </div>
            );
        }
    };
    

    const InsightsEmailWriting = () => {
        if (!userData) {
            return (
                <div className="flex items-center justify-center bg-white w-[90vw] h-[90vh] m-auto">
                    {/* Placeholder for no data */}
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }
    
        // Extract email personalization data
        const emailData = userData.email_personalization;
    
        // UI for each cell in the grid
        const renderCell = (title, key) => (
            <div className="p-4 border rounded-lg shadow-md bg-[#e2ddea]">
                <h3 className="font-semibold text-lg">{title}</h3>
                <p className="text-sm text-gray-500">{emailData.definitions[key]}</p>
                <div className="mt-2">
                    <p className="font-bold">Advice:</p>
                    <p>{emailData.advice[key]}</p>
                </div>
                <div className="mt-2">
                    <p className="font-bold">Example:</p>
                    <p>{emailData.examples[key]}</p>
                </div>
            </div>
        );
    
        if (emailData) {
            return (
                <div className="flex-grow flex flex-col items-center space-y-8 py-1 px-2 md:px-2 overflow-hidden">
                    {/* Scrollable grid container */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full overflow-y-auto max-h-[70vh] p-2">
                        {renderCell("Subject", "Subject")}
                        {renderCell("Subject Length", "Subject Length")}
                        {renderCell("Salutation", "Salutation")}
                        {renderCell("Greeting", "Greeting")}
                        {renderCell("Emojis/GIFs", "Emojis/GIFs")}
                        {renderCell("Bullet Points", "Bullet Points")}
                        {renderCell("Closing Line", "Closing Line")}
                        {renderCell("Closing Greeting", "Closing Greeting")}
                        {renderCell("Complimentary Close", "Complimentary Close")}
                        {renderCell("Tone Of Words", "Tone Of Words")}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex items-center justify-center bg-white w-[90vw] h-[90vh] m-auto">
                    <div className="text-gray-500 text-xl">This Profile cannot be analyzed</div>
                </div>
            );
        }
    };    
    
    const InsightsDealPlanning = () => {
        if (!userData) {
            return (
                <div className="flex items-center justify-center bg-white w-[90vw] h-[90vh] m-auto">
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }
    
        const name = userData.rep_name;
        const text1 = userData.communication_advice.key_traits["Speed"];
        const text2 = userData.communication_advice.key_traits["Decision Drivers"];
    
        if (text1 && text2) {
            return (
                <div className="p-6 bg-[#fff3b9] rounded-lg border border-yellow-300 shadow-md">
                    {/* Title 1 */}
                    <h1 className="text-lg font-bold text-gray-800 mb-2">
                        How Fast (Or Slow) Will {name} Move?
                    </h1>
                    <p className="text-gray-700 mb-4">* {text1}</p>
    
                    {/* Title 2 */}
                    <h1 className="text-lg font-bold text-gray-800 mb-2">
                        Can {name} Take Some Risk Or Not
                    </h1>
                    <p className="text-gray-700">* {text2}</p>
                </div>
            );
        } else {
            return (
                <div className="flex items-center justify-center bg-white w-[90vw] h-[90vh] m-auto">
                    <div className="text-gray-500 text-xl">This Profile cannot be analyzed</div>
                </div>
            );
        }
    };

    const Box1 = ({ userData }) => {
        if (!userData) {
            return (
                <div className="flex items-center justify-center bg-gray-200 rounded-md p-4">
                    <span className="text-gray-500 text-lg">No user data available</span>
                </div>
            );
        }
    
        const {
            profile_image,
            rep_name,
            location,
            user_description,
            communication_advice,
            personality_analysis,
            analysis_confidence,
        } = userData;
    
        const adjectives = communication_advice?.adjectives || [];
        
        // Get DISC archetype and label
        const discArchetype = personality_analysis?.summary?.disc?.archetype;
        const discLabel = personality_analysis?.summary?.disc?.label;
    
        // Convert discLabel to a string if it's an array
        const discLabelString = Array.isArray(discLabel) ? discLabel.join(', ') : discLabel;
        
        // Get confidence score and determine the bubble's appearance
        const confidenceScore = analysis_confidence?.score;
        let confidenceBubble = null;
    
        if (confidenceScore !== null && confidenceScore !== undefined) {
            if (confidenceScore > 60) {
                confidenceBubble = (
                    <div className="bg-green-500 text-white text-md px-2 py-1 rounded-full ml-2" title="Profile Confidence Score">
                        {confidenceScore} %
                    </div>
                );
            } else if (confidenceScore > 30 && confidenceScore <= 60) {
                confidenceBubble = (
                    <div className="bg-yellow-500 text-white text-md px-2 py-1 rounded-full ml-2" title="Profile Confidence Score">
                        {confidenceScore} %
                    </div>
                );
            } else {
                confidenceBubble = (
                    <div className="bg-red-500 text-white text-md px-2 py-1 rounded-full ml-2" title="Profile Confidence Score">
                        {confidenceScore} %
                    </div>
                );
            }
        } else {
            confidenceBubble = (
                <div className="bg-gray-400 text-white text-xs px-2 py-1 rounded-full ml-2" title="Profile Confidence Score">
                    Undefined
                </div>
            );
        }
        

        return (
            <div className="bg-white rounded-md px-4 py-4 shadow-md text-center w-full">
                {/* Profile Image */}
                <div className="flex justify-center mb-4">
                    {profile_image ? (
                        <img
                            src={profile_image}
                            alt="User Profile"
                            className="w-24 h-24 rounded-full"
                        />
                    ) : (
                        <div className="w-24 h-24 bg-gray-300 rounded-full flex items-center justify-center">
                            <span className="text-gray-500 text-lg">No Image</span>
                        </div>
                    )}
                </div>

                {/* Name and Confidence Bubble */}
                <div className="flex justify-center items-center mb-2">
                    <h1 className="text-2xl font-bold text-gray-800">{rep_name}</h1>
                    {confidenceBubble}
                </div>
    
                {/* Adjectives */}
                <div
                    className="flex flex-wrap justify-center gap-2 mb-4"
                    style={{ flexDirection: 'column' }}
                >
                    {adjectives.length > 0 ? (
                        adjectives.map((adjective, index) => (
                            <div
                                key={index}
                                className="bg-gray-100 text-gray-700 px-3 py-1 rounded-md"
                            >
                                {adjective}
                            </div>
                        ))
                    ) : (
                        <span className="text-gray-500">No adjectives available</span>
                    )}
                </div>
    
                {/* Location */}
                <h2 className="text-lg text-gray-600 mb-2 flex items-center justify-center">
                    <FaMapMarkerAlt className="mr-2 text-gray-500" />
                    {location || "No location provided"}
                </h2>
    
                {/* User Description */}
                <div className="border border-gray-300 rounded-md p-3 text-sm text-gray-600 mb-4">
                    {user_description || "No description available"}
                </div>
    
                {/* DISC Archetype and Label Section */}
                {discArchetype && discLabel && (
                    <div className="bg-[#fcc61d] rounded-md p-4 mb-4 text-gray-800">
                        <h1 className="text-xl font-bold">{discArchetype}</h1>
                        <h2 className="text-lg font-semibold">
                            DISC TYPE: {discLabelString}
                        </h2>
                    </div>
                )}
            </div>
        );
    };

    const Box2 = ({ userData }) => {
        if (!userData) {
            return (
                <div className="bg-white rounded-md p-4 shadow-md w-full">
                    <div className="text-gray-500 text-center">Data is loading</div>
                </div>
            );
        }
    
        // Extract relevant data
        const skills = userData.skills || [];
        const education = userData.education?.[0] || null; // Pick the first education item
        const workHistory = userData.work_history?.[0] || null; // Pick the first work history item
        const prographics = userData.prographics || {};
    
        return (
            <div className="bg-white rounded-md p-4 shadow-md flex flex-col gap-4 w-full">
                {/* Skills Section */}
                <div>
                    <h1 className="text-xl font-bold text-gray-800 mb-2">Skill</h1>
                    {skills.length > 0 ? (
                        <ul className="list-disc pl-5 space-y-1">
                            {skills.map((skill, index) => (
                                <li key={index} className="text-gray-700">{skill}</li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-500">No skills available</p>
                    )}
                </div>
                <hr className="border-gray-300" />
    
                {/* Education Section */}
                <div>
                    <h1 className="text-xl font-bold text-gray-800 mb-2">Education</h1>
                    {education ? (
                        <div className="space-y-1 text-gray-700">
                            <p><strong>Degree:</strong> {education.degree || "N/A"}</p>
                            <p><strong>Graduated:</strong> {education.school || "N/A"}</p>
                        </div>
                    ) : (
                        <p className="text-gray-500">No education data available</p>
                    )}
                </div>
                <hr className="border-gray-300" />
    
                {/* Current Position Section */}
                <div>
                    <h1 className="text-xl font-bold text-gray-800 mb-2">Current Position</h1>
                    {workHistory ? (
                        <div className="space-y-1 text-gray-700">
                            <p><strong>Title:</strong> {workHistory.title || "N/A"}</p>
                            <p><strong>Organization:</strong> {workHistory.organization || "N/A"}</p>
                        </div>
                    ) : (
                        <p className="text-gray-500">No work history available</p>
                    )}
                </div>
                <hr className="border-gray-300" />
    
                {/* Working Experience Section */}
                <div>
                    <h1 className="text-xl font-bold text-gray-800 mb-2">Working Experience</h1>
                    <div className="space-y-1 text-gray-700">
                        <p><strong>Occupation:</strong> {prographics.designation || "N/A"}</p>
                        <p><strong>Level:</strong> {prographics.job_level || "N/A"}</p>
                        <p><strong>Experience:</strong> {prographics.experience_in_years ? `${prographics.experience_in_years} year(s)` : "N/A"}</p>
                    </div>
                </div>
            </div>
        );
    };
    
    const Box3 = ({ userData }) => {
        if (!userData?.hiring_behavioural_factors) {
            return (
                <div className="bg-white rounded-md p-4 shadow-md w-full">
                    <div className="text-gray-500 text-center">No behavioral data available</div>
                </div>
            );
        }
    
        const behaviouralFactors = userData.hiring_behavioural_factors;
    
        return (
            <div className="bg-white rounded-md p-4 shadow-md space-y-4 w-full">
                <h1 className="text-xl font-bold text-gray-800 mb-2">Behavioral Factors</h1>
                <div className="space-y-4">
                    {Object.entries(behaviouralFactors).map(([title, details]) => (
                        <div key={title} className="flex flex-col gap-2">
                            {/* Title and Level */}
                            <div className="flex justify-between items-center">
                                <span className="font-semibold text-gray-700 capitalize">{title.replace(/_/g, " ")}</span>
                                <span className="text-sm text-gray-500">{details.level}</span>
                            </div>
                            {/* Score Bar */}
                            <div className="relative w-full h-4 bg-gray-200 rounded-md">
                                <div
                                    className="absolute top-0 left-0 h-4 bg-blue-500 rounded-md"
                                    style={{ width: `${details.score * 10}%` }}
                                />
                            </div>
                            {/* Numeric Score */}
                            <span className="text-sm text-gray-500">Score: {details.score}/10</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const InsigntsRepresentative = () => {
        if (!userData) {
            return (
                <div
                    className="flex items-center justify-center bg-white rounded-xl"
                    style={{
                        width: '90vw',
                        height: '90vh',
                        margin: 'auto',
                    }}
                >
                    {/* Placeholder for no data */}
                    <div className="text-gray-500 text-xl">Data is loading</div>
                </div>
            );
        }

        return (
            <div className="flex flex-col gap-4 w-full p-1 items-start">
                {/* Box 1: User Data */}
                <Box1 userData={userData} />
                {/* Box 2: Infographic Box */}
                <Box2 userData={userData} />
                {/* Box 2: Infographic Box */}
                <Box3 userData={userData} />
            </div>
        );
    

    }

    const Footer = () => {
        return (
        <div className='bg-white flex justify-end shadow-2xl'>
            <div className="mb-3 mt-3">
                <button 
                className="bg-[#223F9E] text-white font-semibold py-2 px-10 mr-[50px] rounded-full"
                onClick={handleBack}
                >
                Back
                </button>
            </div>
        </div>
        )
    }

    return (
        <div>
            {isLoading ? (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <div className="flex items-center space-x-2">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                            <span className="text-lg font-medium">Loading, please wait...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col min-h-screen w-full bg-[#f5f5fd] overflow-auto">
                    {/* Onboarding Header */}
                        <OnboardingHeaderPart />

                    {/* Title Section */}
                    <div className="flex justify-center items-center px-5 py-5 h-[150px]">
                        <h1 className="text-4xl font-bold text-gray-800">
                            Representative Insight Information
                        </h1>
                    </div>

                    {/* Content Section */}
                    <div className="flex flex-col justify-center items-center min-h-screen mb-5">
                        {/* Large Bounding Box */}
                        <div className="flex w-[95%] h-auto min-h-[90vh] border-2 border-gray-300 rounded-xl bg-white">
                            
                            {/* Left Panel */}
                            <div className="w-1/3 h-auto min-h-[90vh] border-2 border-gray-300 rounded-xl bg-gradient-to-b from-[#4c4477] to-[#2b5877] flex items-start justify-center p-4">
                                {/* <span className="text-white">Left Panel</span> */}
                                <InsigntsRepresentative />
                            </div>

                            {/* Right Panel */}
                            <div className="w-2/3 h-auto min-h-[90vh] border-2 border-gray-300 rounded-xl bg-gray-200 flex flex-col items-start p-4">
                                <div className="w-full">
                                    <div className="mb-4">
                                        {/* Title Panel */}
                                        <div className="grid grid-cols-2 gap-4 w-full mb-2">
                                            <button
                                                className={`py-1 px-4 border rounded-lg ${
                                                    selectedProfile === "DISC"
                                                        ? "bg-gradient-to-b from-[#4c4477] to-[#2b5877] text-white"
                                                        : "bg-gray-100 text-gray-700"
                                                }`}
                                                onClick={() => handleProfileClick("DISC")}
                                            >
                                                <h1 className="text-xl font-bold">DISC Profile</h1>
                                            </button>
                                            <button
                                                className={`py-1 px-4 border rounded-lg ${
                                                    selectedProfile === "OCEAN"
                                                        ? "bg-gradient-to-b from-[#4c4477] to-[#2b5877] text-white"
                                                        : "bg-gray-100 text-gray-700"
                                                }`}
                                                onClick={() => handleProfileClick("OCEAN")}
                                            >
                                                <h1 className="text-xl font-bold">OCEAN Profile</h1>
                                            </button>
                                        </div>
                                        {/* Data Panel */}
                                        <ProfileContent profile={selectedProfile} />
                                    </div>

                                    {/* InformationPanel */}
                                    <div className="mb-4">
                                        <InformationPanel title="During A Call or A Meeting">  
                                            <InsightsCallMeeting />
                                        </InformationPanel>
                                        <InformationPanel title="When Netiogating & Closing">
                                            <InsightsNegotiatingClosing/>
                                        </InformationPanel>
                                        <InformationPanel title="For Deal Planing">
                                            <InsightsDealPlanning />
                                        </InformationPanel>
                                        
                                    </div>

                                    <div className="mb-4">
                                        <InformationPanel title="When Cold Calling">
                                            <InsightsColdCalling/>
                                        </InformationPanel>
                                        <InformationPanel title="When Writing Email">
                                            <InsightsEmailWriting />
                                        </InformationPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <Footer />
                </div>

            )}
    </div>
    )
}

export default ViewInsight;